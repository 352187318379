/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */
(function($) {
	function debounce(func, wait, immediate) {
		var timeout;
		return function() {
			var context = this,
				args = arguments;
			var later = function() {
				timeout = null;
				if (!immediate) func.apply(context, args);
			};
			var callNow = immediate && !timeout;
			clearTimeout(timeout);
			timeout = setTimeout(later, wait);
			if (callNow) func.apply(context, args);
		};
	}

	//ajax function
	var page = 2;
	// function getMorePosts() {
	//
	//     //get the needed dom elements
	//     var $body = $('body');
	//     var loader = $('#js-loader');
	//     if (($(document).height() - $(window).height()) - $(window).scrollTop() < 1000 && !$body.hasClass('disable-load')) {
	//         //give the cached dom elements a new class to change content
	//         $body.addClass('disable-load');
	//         loader.addClass('active');
	//         //make the request
	//         $.ajax({
	//             url: '//' + window.location.host + '/wp/wp-admin/admin-ajax.php',
	//             type: 'post',
	//             data: {
	//                 action: 'load_more_posts',
	//                 paged: page
	//             },
	//             success: function (html) {
	//                 if (html === '') {
	//                     loader.removeClass('active');
	//                     $body.removeClass('disable-load');
	//                     window.removeEventListener('click', getMorePosts);
	//
	//                     return;
	//                 }
	//                 //remove the spinner
	//                 loader.removeClass('active');
	//                 //build the html up
	//                 var posts = '<div class="row"><div class="load-in">';
	//                 posts += html;
	//                 posts += '</div></div>';
	//                 $('.load__container').append(posts);
	//                 page++;
	//                 //allow for loading again
	//                 $body.removeClass('disable-load');
	//             }
	//         });
	//
	//
	//
	//     }
	// }

	function getMorePosts() {
		//set some variables
		var $body = document.querySelector('body');
		var $btn = document.querySelectorAll('.js-load-more-btn');
		var $loadContainer = document.querySelector('#load-container');

		//have to loop over all the possible load buttons as had to double content due to design limitation - doh
		for (var i = 0; i < $btn.length; i++) {
			$btn[i].removeEventListener('click', getMorePosts);
			$btn[i].classList.add('fetching-content');
		}
		//make the request

		$.ajax({
			url: '//' + window.location.host + '/wp/wp-admin/admin-ajax.php',
			type: 'post',
			data: {
				action: 'load_more_posts',
				paged: page,
			},
			success: function(html) {
				if (html === '') {
					for (var j = 0; j < $btn.length; j++) {
						$btn[j].classList.remove('fetching-content');
						$btn[j].classList.add('all-content-fetched');
					}

					return;
				}

				//build the load helper
				var $postsContainer = document.createElement('div');
				$postsContainer.classList.add('loaded-in', 'flex');

				//add the returned html inside the first div
				$postsContainer.innerHTML = html;

				//append all that
				$loadContainer.appendChild($postsContainer);
				//increase page variable for next load
				page++;

				//have to loop over all the possible load buttons as had to double content due to design limitation - doh
				for (var i = 0; i < $btn.length; i++) {
					//remove the spinner
					$btn[i].classList.remove('fetching-content');
					//re add the event listener
					$btn[i].addEventListener('click', getMorePosts);
				}
			},
		});
	}

	// Use this variable to set up the common and page specific functions. If you
	// rename this variable, you will also need to rename the namespace below.
	var Sage = {
		// All pages
		common: {
			init: function() {
				// JavaScript to be fired on all pages
				var hamburger = document.querySelector('#js-hamburger');
				var html = document.querySelector('html');
				hamburger.addEventListener('click', function() {
					if (html.classList.contains('nav-open')) {
						html.classList.remove('nav-open');
					} else {
						html.classList.add('nav-open');
					}
				});

				//form pdf download
				var formWrap = document.querySelector('#js-service-pdf');
				if (formWrap) {
					var pdf = formWrap.dataset.pdf;
					if (pdf) {
						document.addEventListener(
							'wpcf7mailsent',
							function(e) {
								window.location.href = pdf;
							},
							false
						);
					}
				}
			},
			finalize: function() {
				// JavaScript to be fired on all pages, after page specific JS is fired

				//sub navigations toggle

				var itemswithSub = document.querySelectorAll('.menu-item-has-children > a');
				// var itemWithSubLink = itemswithSub.querySelector('a');

				for (i = 0; i < itemswithSub.length; i++) {
					//prevent default click as it is in the sub nav
					itemswithSub[i].style.cursor = 'default';
					itemswithSub[i].addEventListener('click', function(e) {
						e.preventDefault();
					});
				}
				//mix it up plugin
				var el = document.querySelector('.mix-it__container');
				if (el) {
					var mixer = mixitup(el);
					var hash = window.location.hash.substr(1);
					if (hash.length) {
						mixer.filter('.' + hash);
					}
				}
				var filterToggle = document.querySelector('#js-filter-toggle');

				//filtering on our work page
				if (filterToggle) {
					var html = document.querySelector('html');
					filterToggle.addEventListener('click', function() {
						//got html from further up page
						if (html.classList.contains('filter-open')) {
							html.classList.remove('filter-open');
						} else {
							html.classList.add('filter-open');
						}
					});
				}
				//fetch more blog posts on click
				var loadMoreBtn = document.querySelectorAll('.js-load-more-btn');
				if (loadMoreBtn) {
					//have to loop over all the possible load buttons as had to double content due to design limitation - doh
					for (var i = 0; i < loadMoreBtn.length; i++) {
						loadMoreBtn[i].addEventListener('click', getMorePosts);
					}
				}

				//fixes header on scroll up only.
				//
				// var header = document.querySelector('#js-header');
				// var body = document.querySelector('body');
				// if(header) {
				//     var headerHeight = header.offsetHeight;
				//     //hoist this for weird js purposes
				//     var currentScroll = 0;
				//
				//
				//
				//
				//     var setHeader = debounce(function() {
				//
				//         //check we are scrolling back up and not down
				//         if(currentScroll > window.scrollY) {
				//             /*
				//                 when we are the height of the header + 1/3 of the height of the viewport
				//                 scrolled then we add a class to help with transitioning on the way back
				//             */
				//             if(currentScroll > headerHeight + (window.innerHeight / 3) ) {
				//                 body.style.paddingTop = headerHeight + 'px';
				//                 header.classList.add('header--fixed', 'scrolling--shown');
				//             } else {
				//                 header.classList.remove('scrolling--shown');
				//                 setTimeout(function() {
				//                     header.classList.remove('header--fixed');
				//                     body.style.paddingTop = 0;
				//                 }, 400)
				//             }
				//         } else {
				//             header.classList.remove('scrolling--shown');
				//             setTimeout(function() {
				//                 header.classList.remove('header--fixed');
				//                 body.style.paddingTop = 0;
				//             }, 400)
				//         }
				//         //update the current scroll var
				//         currentScroll = window.scrollY;
				//     }, 20);
				//
				//     // window.addEventListener('scroll', setHeader);
				//
				// }
				//dynamicll fill form with page title for pdf download pages
				var titleForForm = document.querySelector('#js-page-title-for-form');
				if (titleForForm) {
					var field = document.querySelector('.js-hidden');
					field.value = titleForForm.innerHTML.trim();
				}
			},
		},
		// Home page
		home: {
			init: function() {
				// JavaScript to be fired on the home page
			},
			finalize: function() {
				// JavaScript to be fired on the home page, after the init JS
			},
		},
		portfolio: {
			init: function() {
				// JavaScript to be fired on the home page
			},
			finalize: function() {
				// JavaScript to be fired on the home page, after the init JS
				window.scrollTo(0, 0);
			},
		},
		// About us page, note the change from about-us to about_us.
		about_us: {
			init: function() {
				// JavaScript to be fired on the about us page
			},
		},
	};

	// The routing fires all common scripts, followed by the page specific scripts.
	// Add additional events for more control over timing e.g. a finalize event
	var UTIL = {
		fire: function(func, funcname, args) {
			var fire;
			var namespace = Sage;
			funcname = funcname === undefined ? 'init' : funcname;
			fire = func !== '';
			fire = fire && namespace[func];
			fire = fire && typeof namespace[func][funcname] === 'function';

			if (fire) {
				namespace[func][funcname](args);
			}
		},
		loadEvents: function() {
			// Fire common init JS
			UTIL.fire('common');

			// Fire page-specific init JS, and then finalize JS
			$.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
				UTIL.fire(classnm);
				UTIL.fire(classnm, 'finalize');
			});

			// Fire common finalize JS
			UTIL.fire('common', 'finalize');
		},
	};

	// Load Events
	$(document).ready(UTIL.loadEvents);
})(jQuery); // Fully reference jQuery after this point.
